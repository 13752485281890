import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
// import fetchnavtab from "@/redux/action/navtab";
// import { CategoryFetch } from "./lib/category";
import { Link } from "react-router-dom";
function Citycategory() {
  // Accept keyvalue as an argument
  const [category, setcategory] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);
  const [isLoadingpage, setisLoadingpage] = useState(false);
  const fetchCategory = async (keyvalue) => {
    try {
      const response = await fetch(
        "https://api.jogkart.com/jogkart/citystore/home/category.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ pincode: keyvalue }), // Pass dynamic pincode
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setcategory(data);
      setIsLoading(false);
      setisLoadingpage(true);
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  useEffect(() => {
    const storedCityKey = localStorage.getItem("cityfindkey");
    if (storedCityKey) {
      fetchCategory(storedCityKey);
    }
  }, []);
  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100%",
      }}
    >
      <div style={{ height: "100%" }}>
        {!isLoadingpage ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {/* <div className="spinner"></div> */}
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                paddingBottom: 60,
                backgroundColor: "white",
              }}
            >
              {category.map((item, index) => (
                <div
                  style={{ height: 100, width: "100%", margin: 0 }}
                  // className="product-box-main"
                  key={item.id}
                >
                  <Link
                    style={{
                      cursor: "pointer",
                      outline: "none",
                      boxShadow: "none",
                      WebkitTapHighlightColor: "transparent",
                      textDecoration: "none",
                    }}
                    // href={`/jogdailystore/citycategory/${item.title}`}
                  >
                    <img
                      style={{ height: 50, width: 50 }}
                      height={50}
                      width={50}
                      src={`https://api.jogkart.com/try.php?image=admin/page/${item.image}&height=350&width=350`}
                      // className="product-Image-main"
                      alt={item.title}
                    />
                    {item.titleshow === "true" && (
                      <h5 className="cardtitle">{item.title}</h5>
                    )}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Citycategory;

import React, { useState, useEffect } from "react";

function Newdtsubcat() {
  const [zone, setzone] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [title, settitle] = useState("");
  // Handle the image selection and preview
  const handleImageChange2 = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);

      // Create a preview URL for the image to display
      const previewURL = URL.createObjectURL(file);
      setImagePreview(previewURL);
    }
  };
  const Subcatealldata = async (keyValue) => {
    try {
      const response = await fetch("https://api.jogkart.com/jogkart/dtcat.php");
      const data = await response.json();
      console.log(data.message);
      setzone(data);
    } catch (error) {
      console.log(error);
    }
  };
  const uploaddata = async (keyvalue) => {
    if (imageFile) {
      const formData = new FormData();
      formData.append("image", imageFile);
      formData.append("tittle", title);
      formData.append("maincat", selectedOption);

      try {
        const response = await fetch(
          "https://api.jogkart.com/jogkart/admin/newdtsubcat.php",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          // Handle success (e.g., update state or show a success message)
          alert("Image uploaded successfully");
        } else {
          alert("Failed to upload image");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    } else {
      alert("Please select an image first");
    }
  };

  useEffect(() => {
    Subcatealldata();
  }, []);
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleInputChange = (event) => {
    settitle(event.target.value); // Update the title state with the input value
  };
  const uploadcall = async () => {
    const storedCityKey = localStorage.getItem("cityfindkey");
    if (storedCityKey) {
      uploaddata(storedCityKey);
    }
  };
  return (
    <div>
      <div style={{ marginTop: 30 }}>
        <span className="mybtn btn-file">
          Browse... Sub Category image
          <input
            className="upimage"
            name="thumbimage"
            type="file"
            id="imagethumb"
            onChange={handleImageChange2}
          />
        </span>
      </div>
      <div>
        <div style={{ margin: 10, fontWeight: "bold" }}>
          Title Or Detaild Sub Category Name
        </div>
        <input
          value={title}
          onChange={handleInputChange}
          style={{ width: "90%", height: 40 }}
        />
      </div>
      <div>
        <img
          src={imagePreview}
          alt="Image Preview"
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      </div>
      <div>
        <div style={{ fontWeight: "bold", fontSize: 20 }}>
          Please select Category
        </div>
        <div style={{ margin: "20px" }}>
          <select
            id="dropdown"
            value={selectedOption}
            onChange={handleChange}
            style={{
              padding: "10px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              fontSize: "16px",
              textTransform: "capitalize",
            }}
          >
            <option style={{ textTransform: "capitalize" }} value="" disabled>
              -- Please Select --
            </option>
            {zone.message !== "No categories found for this city" &&
              zone.map((option, index) => (
                <option
                  style={{ textTransform: "capitalize" }}
                  key={index}
                  value={option.name}
                >
                  {option.name}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <div
          onClick={uploadcall}
          style={{
            width: 100,
            height: 40,
            borderRadius: 5,
            backgroundColor: "black",
            color: "white",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Create
        </div>
      </div>
    </div>
  );
}

export default Newdtsubcat;

import React from "react";
import { useState, useEffect } from "react";
import imageCompression from "browser-image-compression";
import { ColorExtractor } from "react-color-extractor";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import cookie from "cookie";

function Makeproduct() {
  const navigate = useNavigate();
  const login = useSelector((state) => state.logindata.login);
  // const [formData, setFormData] = useState([]);
  // const [formDatafinal, setformDatafinal] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [previewforimages, setpreviewforimages] = useState([]);
  const [titleforitem, settitleforitem] = useState("");
  const [titleforbox, settitleforbox] = useState("");
  const [descsgort, setdescsgort] = useState("");
  const [desclong, setdesclong] = useState("");
  const [colourmain, setcolourmain] = useState("");
  const [keywords, setkeywords] = useState("");
  const [orgprice, setorgprice] = useState(null);
  const [orgprice2, setorgprice2] = useState(null);

  const [mainprice, setmainprice] = useState(null);
  const [category, setcategory] = useState("");
  const [typeofitem, settypeofitem] = useState("");
  const [quantity, setquantity] = useState(null);
  const [productcolourid, setproductcolourid] = useState("");
  const [attechid, setattechid] = useState("");
  const [showmsg, setshowmsg] = useState(false);
  const [returnpolicy, setreturnpolicy] = useState("");
  // for thumb image
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImagedesc, setSelectedImagedesc] = useState(null);
  const [previewUrldesc, setPreviewUrldesc] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const [highlightinput, sethighlightinput] = useState({});
  const [highlightinputdesc, sethighlightinputdesc] = useState({});
  const [btqdiscount, setbtqdiscount] = useState("false");
  const [cashondlavb, setcashondlavb] = useState("yes");
  const [sizekeys, setsizekeys] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const selectedSizesString = selectedSizes.join(",");
  const [pruploadmsg, setpruploadmsg] = useState(false);
  const [errorshowmsg, seterrorshowmsg] = useState(false);
  const [sizeforpr, setsizeforpr] = useState("");
  const [allimgsize, setallimgsize] = useState([]);
  const [colouruploading, setcolouruploading] = useState(false);
  const [sellerid, setsellerid] = useState("");
  const [highlightinputdetails, sethighlightinputdetails] = useState([]);
  // console.log(allimgsize);
  const handleSizeToggle = (size) => {
    // Check if the size is already selected
    const isSelected = selectedSizes.includes(size);

    // If selected, remove from the list; otherwise, add to the list
    if (isSelected) {
      setSelectedSizes(selectedSizes.filter((s) => s !== size));
    } else {
      setSelectedSizes([...selectedSizes, size]);
    }
  };
  const handleImageChange = async (event) => {
    const files = event.target.files;
    const filesArray = Array.from(files);

    const compressedImages = await Promise.all(
      filesArray.map(async (file) => {
        try {
          // Skip compression for PNG files
          if (file.type === "image/png") {
            return {
              compressedFile: file, // No compression for PNG
              previewUrl: URL.createObjectURL(file),
              originalName: file.name, // Store the original file name
              originalType: file.type, // Store the original MIME type
            };
          }

          if (file.size <= 800000) {
            return {
              compressedFile: file,
              previewUrl: URL.createObjectURL(file),
              originalName: file.name, // Store the original file name
              originalType: file.type, // Store the original MIME type
            };
          }

          // Determine the appropriate maxSizeMB based on the original image size
          let maxSizeMB;
          if (file.size > 800000 && file.size < 1000000) {
            maxSizeMB = 0.8;
          } else if (file.size > 1000000 && file.size <= 2000000) {
            maxSizeMB = 0.85;
          } else if (file.size > 2000000 && file.size <= 8000000) {
            maxSizeMB = 0.95;
          } else {
            maxSizeMB = 0.95;
          }

          const compressionOptions = {
            maxSizeMB: maxSizeMB,
            maxWidthOrHeight: 5000,
            useWebWorker: true, // Use Web Workers for performance
          };

          const compressedFile = await imageCompression(
            file,
            compressionOptions
          );

          setallimgsize((prevSizes) => {
            if (!Array.isArray(prevSizes)) {
              return [compressedFile.size / 1000];
            } else {
              return [...prevSizes, compressedFile.size / 1000];
            }
          });

          return {
            compressedFile: compressedFile,
            previewUrl: URL.createObjectURL(compressedFile),
            originalName: file.name, // Store the original file name
            originalType: file.type, // Store the original MIME type
          };
        } catch (error) {
          console.error("Image compression error:", error);
          return null;
        }
      })
    );

    // Filter out null values (compression errors)
    const validCompressedImages = compressedImages.filter(
      (image) => image !== null
    );

    // Update state with compressed images and previews
    setpreviewforimages(
      validCompressedImages.map((image) => ({ previewUrl: image.previewUrl }))
    );
    setSelectedImages(
      validCompressedImages.map((image) => image.compressedFile)
    );
  };
  console.log(selectedImages);
  const handleImageChange2 = async (event) => {
    const file = event.target.files[0];

    // Check if the file is a PNG and skip compression if true
    if (file.type === "image/png") {
      setSelectedImage(file);
      setPreviewUrl(URL.createObjectURL(file));
      return; // Skip further processing for PNG
    }

    const options = {
      maxSizeMB: 0.5, // Maximum size allowed in megabytes
      maxWidthOrHeight: 1600, // Maximum width or height
      useWebWorker: true, // Use web workers to speed up compression (if available)
    };

    try {
      const compressedFile = await imageCompression(file, options);

      if (file) {
        // Extract the original extension from the file name
        const fileExtension = file.name.split(".").pop();
        const compressedFileWithOriginalExtension = new File(
          [compressedFile],
          `compressed_image.${fileExtension}`, // Preserve original extension
          { type: file.type } // Preserve original MIME type
        );

        setSelectedImage(compressedFileWithOriginalExtension);
        setPreviewUrl(URL.createObjectURL(compressedFileWithOriginalExtension));
      }
    } catch (error) {
      console.error("Image compression error:", error);
    }
  };

  // for description image
  const handleImageChangedescimage = async (event) => {
    const file = event.target.files[0];

    // Check if the file is a PNG and skip compression if true
    if (file.type === "image/png") {
      setSelectedImagedesc(file);
      setPreviewUrldesc(URL.createObjectURL(file));
      return; // Skip compression for PNG
    }

    const options = {
      maxSizeMB: 1, // Maximum size allowed in megabytes
      maxWidthOrHeight: 4000, // Maximum width or height
      useWebWorker: true, // Use web workers to speed up compression (if available)
    };

    try {
      const compressedFile = await imageCompression(file, options);

      if (file) {
        // Extract the original extension from the file name
        const fileExtension = file.name.split(".").pop();
        const compressedFileWithOriginalExtension = new File(
          [compressedFile],
          `compressed_image.${fileExtension}`, // Preserve original extension
          { type: file.type } // Preserve original MIME type
        );

        setSelectedImagedesc(compressedFileWithOriginalExtension);
        setPreviewUrldesc(
          URL.createObjectURL(compressedFileWithOriginalExtension)
        );
      }
    } catch (error) {
      console.error("Image compression error:", error);
    }
  };

  // extract colour
  const [getcolour, setgetcolour] = useState(null);
  const handlecolourget = async (event) => {
    const file = event.target.files[0];

    if (file) {
      setgetcolour(URL.createObjectURL(file));
    }
  };
  const [colurget, setcolurget] = useState([]);
  const handleColors = (event) => {
    setcolurget(event);
  };
  // fetch category for details and choose category
  const [categorydata, setcategorydata] = useState([]);
  const [dtcategory, setdtcategory] = useState("");
  const subcatfetch = async () => {
    try {
      const response = await fetch(
        "https://api.jogkart.com/jogkart/admin/sub_categoryadmin.php"
      );
      const data = await response.json();
      setcategorydata(data);
    } catch (error) {
      console.log(error);
    }
  };
  const [dtcatgorydata, setdtcatgorydata] = useState([]);
  const dtcatfetch = async () => {
    try {
      const response = await fetch(
        "https://api.jogkart.com/jogkart/dtsbcat.php"
      );
      const data = await response.json();
      setdtcatgorydata(data);
    } catch (error) {
      console.log(error);
    }
  };
  const [productids, setproductids] = useState("");
  const fetchidforpr = async () => {
    try {
      const response = await fetch(
        "https://api.jogkart.com/jogkart/getproductid.php"
      );
      const data = await response.json();
      setproductcolourid(data);
      setcolouruploading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchidforpr2 = async () => {
    try {
      const response = await fetch(
        "https://api.jogkart.com/jogkart/getproductid.php"
      );
      const data = await response.json();
      setproductids(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchidforpr();
    fetchidforpr2();
    subcatfetch();
    dtcatfetch();
  }, []);
  const refreshid = () => {
    fetchidforpr();
  };
  // refresh titlemain
  const rfmaintitle = () => {
    settitleforitem("");
  };
  // refresh title
  const rftitle = () => {
    settitleforbox("");
  };
  // refresh short desc
  const rfshortdsc = () => {
    setdescsgort("");
  };
  // refresh long desc
  const rflongdesc = () => {
    setdesclong("");
  };
  const [keys, setkeys] = useState([]);
  const [keys2, setkeys2] = useState([]);
  const [setailslong, setsetailslong] = useState([]);
  const forgettingkey = async (key) => {
    if (key === "Choose...") {
      setkeys([]);
      return;
    }
    try {
      const response = await fetch(
        `https://api.jogkart.com/jogkart/getkey.php?data=${key}`
      );
      const data = await response.json();
      // console.log(data);
      const hgtdataarray = data[0].hgtkey.split(",");
      const sizekeys = data[0].sizekey.split(",");
      const hgtdataarraydesc = data[0].deschgt.split(",");
      const hgtdataarraydetailslong = data[0].dtlskey.split(",");
      setsetailslong(hgtdataarraydetailslong);
      setkeys2(hgtdataarraydesc);
      console.log(hgtdataarraydesc);
      sethighlightinput({});
      sethighlightinputdesc({});
      sethighlightinputdetails({});
      setkeys(hgtdataarray);
      setsizekeys(sizekeys);
      // console.log(sizekeys);
    } catch (error) {
      console.log(error);
    }
  };
  // category change call function
  const handleCategoryChange = (event) => {
    setcategory(event.target.value);
    // console.log(event.target.value);
    forgettingkey(event.target.value);
  };
  const handleCategoryChange2 = (event) => {
    setdtcategory(event.target.value);
    // forgettingkey(event.target.value);
  };
  // highlight data get

  const handlehgtInputChange = (key, value) => {
    // Remove symbols from the value
    const sanitizedValue = value.replace(/[,-]/g, "");

    sethighlightinput((prevInputValues) => {
      // Create a copy of the current state
      const updatedInputValues = { ...prevInputValues };

      // Update the state regardless of whether the sanitized value is empty or not
      updatedInputValues[key] = sanitizedValue;

      // If the original value is empty, remove the key from the state
      if (value.trim() === "") {
        delete updatedInputValues[key];
      }

      return updatedInputValues;
    });
  };
  // foedesc
  const handlehgtInputChangedesc = (key, value) => {
    // Remove symbols from the value
    const sanitizedValue = value.replace(/[,-]/g, "");

    sethighlightinputdesc((prevInputValues) => {
      // Create a copy of the current state
      const updatedInputValues = { ...prevInputValues };

      // Update the state regardless of whether the sanitized value is empty or not
      updatedInputValues[key] = sanitizedValue;

      // If the original value is empty, remove the key from the state
      if (value.trim() === "") {
        delete updatedInputValues[key];
      }

      return updatedInputValues;
    });
  };
  // for long details
  const handlehgtInputChangedetails = (key, value) => {
    // Remove symbols from the value
    const sanitizedValue = value.replace(/[,-]/g, "");

    sethighlightinputdetails((prevInputValues) => {
      // Create a copy of the current state
      const updatedInputValues = { ...prevInputValues };

      // Update the state regardless of whether the sanitized value is empty or not
      updatedInputValues[key] = sanitizedValue;

      // If the original value is empty, remove the key from the state
      if (value.trim() === "") {
        delete updatedInputValues[key];
      }

      return updatedInputValues;
    });
  };
  function sanitizeKeywords(keywords) {
    // Define the characters you want to remove
    const unwantedCharacters = /['"&\/\\?]/g;

    // Replace unwanted characters with an empty string
    return keywords.replace(unwantedCharacters, "");
  }

  // // Example usage
  // let keywordsss = '"Some "exam/ple"& - ? keywords,ddd,gg  with & special / characters';
  // let sanitizedKeywords = sanitizeKeywords(keywordsss);
  // console.log(sanitizedKeywords);
  const postData = (event) => {
    setshowmsg(true);
    const formattedData = Object.entries(highlightinput)
      .map(([key, value]) => `${key.trim().toLowerCase()}-${value.trim()}`)
      .join(", ");
    const formattedDatadesc = Object.entries(highlightinputdesc)
      .map(([key, value]) => `${key.trim().toLowerCase()}-${value.trim()}`)
      .join(", ");
    const formattedDatadetails = Object.entries(highlightinputdetails)
      .map(([key, value]) => `${key.trim().toLowerCase()}-${value.trim()}`)
      .join(", ");
    const serverEndpoint = "https://api.jogkart.com/_new_pro2.php"; // Replace with your actual server endpoint
    const formData = new FormData();
    formData.append("tittle", sanitizeKeywords(titleforitem));
    formData.append("maintittle", sanitizeKeywords(titleforbox));
    formData.append("shortdesc", sanitizeKeywords(descsgort));
    formData.append("longdesc", sanitizeKeywords(desclong));
    formData.append("colour", colourmain);
    formData.append("keywords", sanitizeKeywords(keywords));
    formData.append("orgprice", orgprice);
    formData.append("saleprice", mainprice);
    formData.append("catselect", category);
    formData.append("catselect2", dtcategory);
    formData.append("typeselect", typeofitem);
    formData.append("quantity", quantity);
    formData.append("product_id", productcolourid); //this for make same colour
    formData.append("attech_product_id", productids); // this is coming from databaze direch this in not changable
    formData.append("attechid", attechid); // for attech product to direct attech
    formData.append("highlight", sanitizeKeywords(formattedData));
    formData.append("cmpdetails", sanitizeKeywords(formattedDatadetails));
    formData.append("highlightdesc", sanitizeKeywords(formattedDatadesc));
    formData.append("returnpolicy", returnpolicy);
    formData.append("btqdisc", btqdiscount);
    formData.append("allsize", selectedSizesString);
    formData.append("sizeforpr", sizeforpr);
    formData.append("cashondlavb", cashondlavb);
    formData.append("sellerid", sellerid);
    formData.append("countryorg", sanitizeKeywords(countryorg));
    formData.append("genericname", sanitizeKeywords(genericname));
    formData.append("menufec", sanitizeKeywords(menufec));
    formData.append("importdt", sanitizeKeywords(importdt));
    formData.append("packdt", sanitizeKeywords(packdt));
    // ... (append other form data)
    // console.log(selectedImages);
    // console.log(selectedImage);
    selectedImages.forEach((blob, index) => {
      // Extract the original file extension from the blob's name
      const fileExtension = blob.name.split(".").pop();

      // Construct the new file name with the original extension
      const fileName = `image${index}.${fileExtension}`;

      // Create a new File object with the original extension and MIME type
      const file = new File([blob], fileName, {
        type: blob.type, // Use the original MIME type
      });

      // Append the file to the form data
      formData.append(`image${index}`, file);
    });

    // console.log(formDataObject); // Log the formDataArray

    if (selectedImage) {
      // Ensure formData is defined in the scope where it's used
      formData.append("thumbimage", selectedImage);

      // Continue with your form submission logic here
    }
    // for desc image
    if (selectedImagedesc) {
      // const file = new File([selectedImagedesc], `compressed_image.jpg`, {
      //   type: "image/jpeg",
      // });
      formData.append("descimage", selectedImagedesc);
    }
    // console.log(formData);
    fetch(serverEndpoint, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "success") {
          setSelectedImages([]);
          setpreviewforimages([]);
          setSelectedImage(null);
          setSelectedImagedesc(null);

          setPreviewUrl("");
          setshowmsg(false);
          setpruploadmsg(true);
          fetchidforpr2();
          const confirmed = window.confirm(
            "Do you want to list more colors for this product?"
          );
          if (confirmed) {
            // User clicked "OK", do something
            // console.log("User clicked OK");
            // console.log("yes");
            // console.log("yes");
            setcolouruploading(true);
            setshowmsg(false);
            setTimeout(() => {
              setpruploadmsg(false);
            }, 3000);
          } else {
            fetchidforpr();
            setcolouruploading(false);
            setTimeout(() => {
              setpruploadmsg(false);
            }, 3000);
            setshowmsg(false);
          }
        } else if (data.message === "failed") {
          setshowmsg(false);
          setpruploadmsg(false);
          seterrorshowmsg(true);
          setTimeout(() => {
            seterrorshowmsg(false);
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Error posting data:", error);
        // Handle any error logic here
      });
  };
  const [maintitleerror, setmaintitleerror] = useState("");
  const [boxtitleeror, setboxtitleeror] = useState("");
  const [mainimagelength, setmainimagelength] = useState("");
  const [shortdescerror, setshortdescerror] = useState("");
  const [longdescerror, setlongdescerror] = useState("");
  const [keywordserror, setkeywordserror] = useState("");
  const [priceerror, setpriceerror] = useState("");
  const [mainpriceerror, setmainpriceerror] = useState("");
  const [categoryerror, setcategoryerror] = useState("");
  const [typeitemerror, settypeitemerror] = useState("");
  const [quantityerror, setquantityerror] = useState("");
  const [coloriderror, setcoloriderror] = useState("");
  const [thumbimageerror, setthumbimageerror] = useState("");
  const [returnpolicyerror, setreturnpolicyerror] = useState("");
  const [btqdiscounterror, setbtqdiscounterror] = useState("");
  const [selleriderror, setselleriderror] = useState("");
  const [countryorg, setcountryorg] = useState("");
  const [genericname, setgenericname] = useState("");
  const [menufec, setmenufec] = useState("");
  const [importdt, setimportdt] = useState("");
  const [packdt, setpackdt] = useState("");

  const postdatabutton = () => {
    setmaintitleerror("");
    setboxtitleeror(""); // Corrected spelling here
    setmainimagelength("");
    setshortdescerror("");
    setlongdescerror("");
    setkeywordserror("");
    setpriceerror("");
    setmainpriceerror("");
    setcategoryerror("");
    settypeitemerror("");
    setquantityerror("");
    setcoloriderror("");
    setthumbimageerror("");
    setreturnpolicyerror("");
    setbtqdiscounterror("");
    setselleriderror("");

    if (titleforitem.length < 20) {
      setmaintitleerror("please give valid title at least 20 characters");
      return;
    } else if (titleforbox.length < 5) {
      setboxtitleeror("please give valid title at least 5 characters");
      return;
    } else if (selectedImages.length < 1) {
      setmainimagelength("please give at least 2 images");
      return;
    } else if (desclong.length < 40) {
      setlongdescerror("please give valid description at least 40 characters");
      return;
    } else if (keywords.length < 20) {
      setkeywordserror("please give valid keywords at least 20 characters");
      return;
    } else if (orgprice === null) {
      setpriceerror("please give valid price at least 1 character");
      return;
    } else if (mainprice === null) {
      setmainpriceerror("please give valid price at least 1 character");
      return;
    } else if (
      (!category || category.length < 1 || category.trim() === "") &&
      (!dtcategory || dtcategory.length < 1 || dtcategory.trim() === "")
    ) {
      setcategoryerror("please choose category");
      return;
    } else if (typeofitem.length < 1 || typeofitem === "Choose...") {
      settypeitemerror("please choose typeofitem");
      return;
    } else if (quantity === null) {
      setquantityerror("please choose quantity");
      return;
    } else if (productcolourid.length < 1) {
      setcoloriderror("please give valid colourid");
      return;
    } else if (selectedImage === null) {
      setthumbimageerror("please give valid thumb image");
      return;
    } else if (returnpolicy.length < 1 || returnpolicy === "Choose...") {
      setreturnpolicyerror("please give valid policy of return policy");
      return;
    } else if (sellerid.length < 10 || sellerid.length > 10) {
      setselleriderror("please give valid Seller ID");
      return;
    } else if (btqdiscount.length < 1) {
      setbtqdiscounterror("please give valid boutique discount option");
      return;
    } else {
      postData();
    }
  };
  const selectcolour = (colour) => {
    // console.log(colour);
    setcolourmain(colour);
  };
  const checkadminexists = async (user) => {
    const apiUrl = "https://api.jogkart.com/jogkart/admin/get_user.php";
    const requestBody = {
      user: user,
    };
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      if (response.ok) {
        const userData = await response.json();
        if (userData.status === "success" && userData.message === "success") {
          if (userData.userid === "yes") {
            sethandleuserget(false);
          } else {
            navigate(`/`);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getCookie = (cookieName) => {
    const cookies = cookie.parse(document.cookie);
    return cookies[cookieName];
  };
  const [handleuserget, sethandleuserget] = useState(true);
  useEffect(() => {
    const cookieValue = getCookie("jogwebtr");

    if (login.status === "success" || cookieValue) {
      if (handleuserget) {
        checkadminexists(cookieValue);
      }
    } else {
      navigate(`/`);
    }
  });

  return (
    <div>
      {showmsg && (
        <div className="pruploadtellingboxchild" style={{}}>
          Uploading Product Please Wait
        </div>
      )}
      {pruploadmsg && (
        <div
          className="pruploadtellingboxchild"
          style={{ color: "green", fontWeight: "bold" }}
        >
          Product Uploaded Successfully
        </div>
      )}
      {errorshowmsg && (
        <div
          className="pruploadtellingboxchild"
          style={{ color: "red", fontWeight: "bold" }}
        >
          Please Try Again Product Not Uploaded
        </div>
      )}

      <div style={{ display: "flex", justifyContent: "center" }}>
        {colouruploading && (
          <div
            style={{
              color: "red",
              fontWeight: "bold",
              position: "fixed",
              backgroundColor: "white",
              zIndex: 10000,
              width: "100%",
              fontSize: 17,
            }}
          >
            You are Uploading More Colour OF Previous Product
          </div>
        )}
      </div>
      <div
        style={{
          marginBottom: 20,
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div>
          <div className="" style={{ marginBottom: 20 }}>
            <label for="formFileMultiple" className="form-label text-dark">
              <h1>Product Upload</h1>
            </label>
            <h5>All Image</h5>
            <div style={{ color: "red", textTransform: "capitalize" }}>
              {mainimagelength}
            </div>
            <span className="mybtn btn-file">
              Browse... list images
              <input
                size="60"
                className="upimage"
                name="image[]"
                type="file"
                id="image"
                onChange={handleImageChange}
                multiple
              />
            </span>
          </div>
          {previewforimages.map((image, index) => (
            <img
              key={index}
              src={image.previewUrl}
              alt={`Preview ${index + 1}`}
              style={{
                width: "100px",
                height: "100px",
                margin: "5px",
                objectFit: "contain",
              }}
            />
          ))}
          <div className="mb-3">
            <label for="formFileMultiple" className="form-label text-dark">
              <h5>Thumb Image</h5>
            </label>
            <div style={{ color: "red", textTransform: "capitalize" }}>
              {thumbimageerror}
            </div>
            <span className="mybtn btn-file">
              Browse... thumb image
              <input
                className="upimage"
                name="thumbimage"
                type="file"
                id="imagethumb"
                onChange={handleImageChange2}
              />
            </span>
          </div>
        </div>
      </div>
      {selectedImage && (
        <div className="mt-3">
          <img
            src={previewUrl}
            alt="Preview"
            style={{
              width: "100px",
              height: "100px",
              margin: "5px",
              objectFit: "contain",
            }}
          />
        </div>
      )}
      <div className=" input-group-lg">
        <div for="exampleFormControlInput1" className="labeltext">
          Tittle for Item details
        </div>
        <div style={{ color: "red", textTransform: "capitalize" }}>
          {maintitleerror}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <textarea
            value={titleforitem}
            onChange={(event) => settitleforitem(event.target.value)}
            style={{ height: 150, resize: "none" }}
            type="text"
            className="wdth60ps"
            name="tittle"
            placeholder="Tittle"
          ></textarea>
          <div>
            <button
              onClick={rfmaintitle}
              style={{
                borderRadius: 5,
                border: "none",
                backgroundColor: "white",
                color: "white",
                fontWeight: "600",
                padding: 5,
                marginTop: 5,
                borderWidth: 1,
                borderColor: "#dedede",
                position: "absolute",
              }}
            >
              <img
                style={{ height: 20 }}
                src={require("./icon/reloadic.png")}
                alt="Add"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="input-group-lg">
        <div for="exampleFormControlInput1" className="labeltext">
          Main Tittle for box
        </div>
        <div style={{ color: "red", textTransform: "capitalize" }}>
          {boxtitleeror}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <textarea
            value={titleforbox}
            onChange={(event) => settitleforbox(event.target.value)}
            className="wdth60ps"
            type="text"
            placeholder="Tittle"
          ></textarea>
          <div>
            <button
              onClick={rftitle}
              style={{
                borderRadius: 5,
                border: "none",
                backgroundColor: "white",
                color: "white",
                fontWeight: "600",
                padding: 5,
                marginTop: 5,
                borderWidth: 1,
                borderColor: "#dedede",
                position: "absolute",
              }}
            >
              <img
                style={{ height: 20 }}
                src={require("./icon/reloadic.png")}
                alt="Add"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="input-group-lg">
        <div for="exampleFormControlInput1" className="labeltext">
          Desc Short (Optional)
        </div>
        <div style={{ color: "red", textTransform: "capitalize" }}>
          {shortdescerror}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <textarea
            value={descsgort}
            onChange={(event) => setdescsgort(event.target.value)}
            type="text"
            name="shortdesc"
            className="wdth60ps"
            aria-label="Sizing example input"
            placeholder="Desc Short"
            aria-describedby="inputGroup-sizing-lg"
          ></textarea>
          <div>
            <button
              onClick={rfshortdsc}
              style={{
                borderRadius: 5,
                border: "none",
                backgroundColor: "white",
                color: "white",
                fontWeight: "600",
                padding: 5,
                marginTop: 5,
                borderWidth: 1,
                borderColor: "#dedede",
                position: "absolute",
              }}
            >
              <img
                style={{ height: 20 }}
                src={require("./icon/reloadic.png")}
                alt="Add"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="mb-3">
        <label for="formFileMultiple" className="form-label text-dark">
          <h5>Description Images (Optional)</h5>
        </label>
        <div style={{ color: "red", textTransform: "capitalize" }}>
          {thumbimageerror}
        </div>
        <span className="mybtn btn-file">
          Browse... Description image
          <input
            className="upimage"
            name="thumbimage"
            type="file"
            id="imagethumb"
            onChange={handleImageChangedescimage}
          />
        </span>
      </div>
      {selectedImagedesc && (
        <div className="mt-3">
          <img
            src={previewUrldesc}
            alt="Preview"
            style={{
              width: "100px",
              height: "100px",
              margin: "5px",
              objectFit: "contain",
            }}
          />
        </div>
      )}
      <div className="input-group mb-3">
        <div className="labeltext" for="inputGroupSelect01">
          Description Highlight
          <span style={{ color: "red" }}>*</span>
        </div>
        {keys2.length > 1 &&
          keys2.map((key, index) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
              }}
              key={index}
            >
              <div
                style={{
                  width: "20%",
                  fontWeight: "600",
                  textTransform: "capitalize",
                }}
              >
                {key}
              </div>
              <input
                className="wdth60ps hgt40"
                type="text"
                value={highlightinputdesc[key] || ""}
                onChange={(e) => handlehgtInputChangedesc(key, e.target.value)}
              />
            </div>
          ))}
      </div>
      <div className="input-group-lg">
        <div for="exampleFormControlInput1" className="labeltext">
          Desc Long
        </div>
        <div style={{ color: "red", textTransform: "capitalize" }}>
          {longdescerror}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <textarea
            value={desclong}
            onChange={(event) => setdesclong(event.target.value)}
            style={{ height: 150 }}
            type="text"
            name="longdesc"
            className="wdth60ps"
            aria-label="Sizing example input"
            placeholder="Desc Long"
            aria-describedby="inputGroup-sizing-lg"
          ></textarea>
          <div>
            <button
              onClick={rflongdesc}
              style={{
                borderRadius: 5,
                border: "none",
                backgroundColor: "white",
                color: "white",
                fontWeight: "600",
                padding: 5,
                marginTop: 5,
                borderWidth: 1,
                borderColor: "#dedede",
                position: "absolute",
              }}
            >
              <img
                style={{ height: 20 }}
                src={require("./icon/reloadic.png")}
                alt="Add"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div for="formFileMultiple" className="labeltext">
          <h5>Colour Image</h5>
        </div>

        <input
          onChange={handlecolourget}
          className="wdth60ps"
          type="file"
          id="colourimage"
        />
      </div>
      <ColorExtractor src={getcolour} getColors={handleColors}>
        <img
          style={{ height: 150, display: "none" }}
          src={getcolour}
          alt="Target Image"
        />
      </ColorExtractor>
      {colurget.map((item, index) => (
        <div
          onClick={() => selectcolour(item)}
          style={{
            backgroundColor: item,
            width: 30,
            height: 30,
            margin: 10,
            display: "inline-flex",
          }}
        ></div>
      ))}
      <div className="input-group input-group-lg">
        <div className="labeltext" id="inputGroup-sizing-lg">
          Colour
        </div>
        <input
          value={colourmain}
          onChange={(event) => setcolourmain(event.target.value)}
          id="colorb"
          type="text"
          name="colour"
          className="wdth60ps hgt40"
          aria-label="Sizing example input"
          placeholder="colour"
          aria-describedby="inputGroup-sizing-lg"
        />
      </div>
      <input
        onChange={(event) => setcolourmain(event.target.value)}
        value={colourmain}
        type="color"
        id="colorPicker"
      />
      <div className="input-group input-group-lg">
        <div className="labeltext" id="inputGroup-sizing-lg">
          Key Words
        </div>
        <div style={{ color: "red", textTransform: "capitalize" }}>
          {keywordserror}
        </div>
        <input
          value={keywords}
          onChange={(event) => setkeywords(event.target.value)}
          type="text"
          name="keywords"
          className="wdth60ps hgt40"
          aria-label="Sizing example input"
          placeholder="Keywords"
          aria-describedby="inputGroup-sizing-lg"
        />
      </div>
      <div className="input-group input-group-lg">
        <div className="labeltext" id="inputGroup-sizing-lg">
          Size if available
        </div>
        <div style={{ color: "red", textTransform: "capitalize" }}></div>
        <input
          value={sizeforpr}
          onChange={(event) => setsizeforpr(event.target.value)}
          type="text"
          name="size"
          className="wdth60ps hgt40"
          aria-label="Sizing example input"
          placeholder="Size(Optional)"
          aria-describedby="inputGroup-sizing-lg"
        />
      </div>
      <div className="input-group input-group-lg">
        <div className="labeltext" id="inputGroup-sizing-lg">
          Orginal Price
        </div>
        <div style={{ color: "red", textTransform: "capitalize" }}>
          {priceerror}
        </div>
        <input
          value={orgprice}
          onChange={(event) => setorgprice(event.target.value)}
          type="number"
          name="orgprice"
          className="wdth60ps hgt40"
          aria-label="Sizing example input"
          placeholder="Orginal Price"
          aria-describedby="inputGroup-sizing-lg"
        />
      </div>
      <div className="input-group input-group-lg">
        <div className="labeltext" id="inputGroup-sizing-lg">
          Sale Price
        </div>
        <div style={{ color: "red", textTransform: "capitalize" }}>
          {mainpriceerror}
        </div>
        <input
          value={mainprice}
          onChange={(event) => setmainprice(event.target.value)}
          type="number"
          name="saleprice"
          className="wdth60ps hgt40"
          aria-label="Sizing example input"
          placeholder="Sale Price"
          aria-describedby="inputGroup-sizing-lg"
        />
      </div>
      <div className="input-group mb-3">
        <div className="labeltext" for="inputGroupSelect01">
          Category
        </div>
        <div style={{ color: "red", textTransform: "capitalize" }}>
          {categoryerror}
        </div>
        <select
          value={category}
          onChange={handleCategoryChange}
          //   onChange={(event) => setcategory(event.target.value)}
          name="catselect"
          className="wdth60ps hgt40"
          id="catselect"
        >
          <option selected></option>
          {categorydata.length > 0 &&
            categorydata.map((item, index) => (
              <option key={item.id}>{item.title}</option>
            ))}
        </select>
      </div>
      {/* dtcat */}
      <div className="input-group mb-3">
        <div className="labeltext" for="inputGroupSelect01">
          Dt Category
        </div>
        <div style={{ color: "red", textTransform: "capitalize" }}>
          {categoryerror}
        </div>
        <select
          value={dtcategory}
          onChange={handleCategoryChange2}
          //   onChange={(event) => setcategory(event.target.value)}
          name="catselect"
          className="wdth60ps hgt40"
          id="catselect"
        >
          <option selected></option>
          {dtcatgorydata.length > 0 &&
            dtcatgorydata.map((item, index) => (
              <option key={item.id}>{item.title}</option>
            ))}
        </select>
      </div>
      <div className="input-group mb-3">
        <div className="labeltext" for="inputGroupSelect01">
          Type
        </div>
        <div style={{ color: "red", textTransform: "capitalize" }}>
          {typeitemerror}
        </div>
        <select
          value={typeofitem}
          onChange={(event) => settypeofitem(event.target.value)}
          className="wdth60ps hgt40"
          name="typeselect"
          id="typeselect"
        >
          <option selected>Choose...</option>
          <option value="fabric">fabric</option>
          <option value="other">other</option>
        </select>
      </div>
      <div className="input-group mb-3">
        <div className="labeltext" for="inputGroupSelect01">
          Available For Return{" "}
        </div>
        <div style={{ color: "red", textTransform: "capitalize" }}>
          {returnpolicyerror}
        </div>
        <select
          value={returnpolicy}
          onChange={(event) => setreturnpolicy(event.target.value)}
          className="wdth60ps hgt40"
          name="typeselect"
          id="typeselect"
        >
          <option selected>Choose...</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </div>
      <div className="input-group mb-3">
        <div className="labeltext" for="inputGroupSelect01">
          Boutique Discount
        </div>
        <div style={{ color: "red", textTransform: "capitalize" }}>
          {btqdiscounterror}
        </div>
        <select
          value={btqdiscount}
          onChange={(event) => setbtqdiscount(event.target.value)}
          className="wdth60ps hgt40"
          name="typeselect"
          id="typeselect"
        >
          <option value="false" selected>
            No
          </option>
          <option value="true">Yes</option>
        </select>
      </div>
      <div className="input-group mb-3">
        <div
          style={{ color: "green" }}
          className="labeltext"
          for="inputGroupSelect01"
        >
          Cash On Delivery
        </div>
        <div style={{ color: "red", textTransform: "capitalize" }}>
          {btqdiscounterror}
        </div>
        <select
          value={cashondlavb}
          onChange={(event) => setcashondlavb(event.target.value)}
          className="wdth60ps hgt40"
          name="typeselect"
          id="typeselect"
        >
          <option value="no">No</option>
          <option value="yes" selected>
            Yes
          </option>
        </select>
      </div>
      <div className="input-group input-group-lg">
        <div className="labeltext" id="inputGroup-sizing-lg">
          Quantity
        </div>
        <div style={{ color: "red", textTransform: "capitalize" }}>
          {quantityerror}
        </div>
        <input
          value={quantity}
          onChange={(event) => setquantity(event.target.value)}
          type="number"
          name="quantity"
          className="wdth60ps hgt40"
          aria-label="Sizing example input"
          placeholder="Quantity"
          aria-describedby="inputGroup-sizing-lg"
        />
      </div>
      <div className="input-group input-group-lg">
        <div className="labeltext" id="inputGroup-sizing-lg">
          Product Colour Id
        </div>
        <div style={{ color: "#ef9a9a" }}>
          Please Do NOT Change This Will Auto Change
        </div>
        <div style={{ color: "red", textTransform: "capitalize" }}>
          {coloriderror}
        </div>
        <input
          onChange={(event) => setproductcolourid(event.target.value)}
          type="number"
          value={productcolourid}
          name="product_id"
          className="wdth60ps hgt40"
          aria-label="Sizing example input"
          placeholder="product id"
          aria-describedby="inputGroup-sizing-lg"
        />
      </div>
      <span>
        {colouruploading && (
          <div
            style={{
              textAlign: "center",
              fontSize: "1.2rem",
              marginTop: "20px",
              color: "red",
            }}
          >
            If you are done uploading all colors of the previous product, please
            refresh the page.
          </div>
        )}
        <button
          onClick={refreshid}
          style={{
            borderRadius: 5,
            border: "none",
            backgroundColor: "green",
            color: "white",
            fontWeight: "600",
            padding: 5,
            marginTop: 5,
            borderWidth: 1,
            borderColor: "#dedede",
          }}
        >
          Refresh
        </button>
      </span>
      <div className="input-group input-group-lg">
        <div className="labeltext" id="inputGroup-sizing-lg">
          Product SKU Number
          <div>{productids}</div>
        </div>
      </div>
      <div>
        <div style={{ color: "blue", fontWeight: "bold" }}>Seller ID</div>
        <div style={{ color: "red", textTransform: "capitalize" }}>
          {selleriderror}
        </div>
        <input
          value={sellerid}
          onChange={(event) => setsellerid(event.target.value)}
          type="text"
          name="attechid"
          className="wdth60ps hgt40"
          aria-label="Sizing example input"
          placeholder="Seller id"
          aria-describedby="inputGroup-sizing-lg"
        />
      </div>
      <div className="input-group input-group-lg">
        <div className="labeltext" id="inputGroup-sizing-lg">
          Attech id
        </div>
        <input
          value={attechid}
          onChange={(event) => setattechid(event.target.value)}
          type="text"
          name="attechid"
          className="wdth60ps hgt40"
          aria-label="Sizing example input"
          placeholder="Attech id"
          aria-describedby="inputGroup-sizing-lg"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
          className="input-group mb-3"
        >
          <div
            style={{ margin: 10 }}
            className="labeltext"
            for="inputGroupSelect01"
          >
            Available sizes
            <span style={{ color: "red" }}>*</span>
          </div>
          {sizekeys.length > 1 &&
            sizekeys.map((size, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: 10,
                }}
              >
                <label
                  htmlFor={size}
                  style={{
                    marginLeft: 5,
                    fontWeight: "600",
                    textTransform: "uppercase",
                    marginRight: 5,
                  }}
                >
                  {size}
                </label>
                <input
                  type="checkbox"
                  id={size}
                  checked={selectedSizes.includes(size)}
                  onChange={() => handleSizeToggle(size)}
                />
              </div>
            ))}
        </div>
        <div className="input-group mb-3">
          <div className="labeltext" for="inputGroupSelect01">
            Highlight
            <span style={{ color: "red" }}>*</span>
          </div>
          {keys.length > 1 &&
            keys.map((key, index) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 10,
                }}
                key={index}
              >
                <div
                  style={{
                    width: "20%",
                    fontWeight: "600",
                    textTransform: "capitalize",
                  }}
                >
                  {key}
                </div>
                <input
                  className="wdth60ps hgt40"
                  type="text"
                  value={highlightinput[key] || ""}
                  onChange={(e) => handlehgtInputChange(key, e.target.value)}
                />
              </div>
            ))}
        </div>
        <div className="input-group mb-3">
          <div className="labeltext" for="inputGroupSelect01">
            Description Highlight
            <span style={{ color: "red" }}>*</span>
          </div>
          {setailslong.length > 1 &&
            setailslong.map((key, index) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 10,
                }}
                key={index}
              >
                <div
                  style={{
                    width: "20%",
                    fontWeight: "600",
                    textTransform: "capitalize",
                  }}
                >
                  {key}
                </div>
                <textarea
                  className="wdth60ps hgt40"
                  type="text"
                  style={{ height: 150, resize: "none" }}
                  value={highlightinputdetails[key] || ""}
                  onChange={(e) =>
                    handlehgtInputChangedetails(key, e.target.value)
                  }
                />
                <div>
                  <button
                    // onClick={rfmaintitle}
                    onClick={(e) => handlehgtInputChangedetails(key, "")}
                    style={{
                      borderRadius: 5,
                      border: "none",
                      backgroundColor: "white",
                      color: "white",
                      fontWeight: "600",
                      padding: 5,
                      marginTop: 5,
                      borderWidth: 1,
                      borderColor: "#dedede",
                      position: "absolute",
                    }}
                  >
                    <img
                      style={{ height: 20 }}
                      src={require("./icon/reloadic.png")}
                      alt="Add"
                    />
                  </button>
                </div>
              </div>
            ))}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              width: "20%",
              fontWeight: "600",
              textTransform: "capitalize",
            }}
          >
            Country Of Origin
          </div>
          <input
            className="wdth60ps hgt40"
            type="text"
            value={countryorg}
            onChange={(e) => setcountryorg(e.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              width: "20%",
              fontWeight: "600",
              textTransform: "capitalize",
            }}
          >
            Generic Name
          </div>
          <input
            className="wdth60ps hgt40"
            type="text"
            value={genericname}
            onChange={(e) => setgenericname(e.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              width: "20%",
              fontWeight: "600",
              textTransform: "capitalize",
            }}
          >
            Menufecturer
          </div>
          <input
            className="wdth60ps hgt40"
            type="text"
            value={menufec}
            onChange={(e) => setmenufec(e.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              width: "20%",
              fontWeight: "600",
              textTransform: "capitalize",
            }}
          >
            Importer
          </div>
          <input
            className="wdth60ps hgt40"
            type="text"
            value={importdt}
            onChange={(e) => setimportdt(e.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              width: "20%",
              fontWeight: "600",
              textTransform: "capitalize",
            }}
          >
            Packer
          </div>
          <input
            className="wdth60ps hgt40"
            type="text"
            value={packdt}
            onChange={(e) => setpackdt(e.target.value)}
          />
        </div>

        {/* </div> */}
        <div style={{ margin: 10 }}>
          <button
            className="productupbtn"
            style={{
              backgroundColor: colouruploading ? "green" : "#0163AE",
              border: "none",
              padding: 10,
              borderRadius: 5,
              color: "white",
              cursor: "pointer",
            }}
            onClick={postdatabutton}
          >
            {colouruploading ? " Upload Colour of Product" : " Upload Product"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Makeproduct;

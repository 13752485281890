import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import "./component/css/style.css";
import { Routes, Route, Link } from "react-router-dom";

import Home from "./component/Home";
import Makeproduct from "./component/Makeproduct";
import Login from "./component/Login";
import Totalproducts from "./component/Totalproducts";
import Totalorder from "./component/Totalorder";
import Ordership from "./component/Ordership";
import Orderout from "./component/Orderout";
import Compelete from "./component/Compelte";
import Canceledorder from "./component/Canceledorder";
import Returnrqst from "./component/Returnrqst";
import Compeletereturn from "./component/Compeletereturn";
import Cancelledreturn from "./component/Cancelledreturn";
// for citystore
import Cityhome from "./component/citystore/Home";
import Citypradd from "./component/citystore/citcomp/Addproduct";
import Citallcat from "./component/citystore/citcomp/Allcategory";
import Citallsubcat from "./component/citystore/citcomp/Allsubcategory";
import Citallprod from "./component/citystore/citcomp/Allproduct";
import Newcategory from "./component/citystore/citcomp/catsubrl/Newcategory";
import Newsubcategory from "./component/citystore/citcomp/catsubrl/Newsubcategory";
import Newdtcatgory from "./component/citystore/citcomp/catsubrl/Newdtcatgory";
import Newdtsubcategory from "./component/citystore/citcomp/catsubrl/Newdtsubcategory";
import Newdtcat from "./component/Newdtcat";
import Newdtsubcat from "./component/Newdtsubcat";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/cityhome" element={<Cityhome />} />

        <Route exact path="/home" element={<Home />} />
        <Route exact path="/makeproduct" element={<Makeproduct />} />
        <Route exact path="/totalproducts" element={<Totalproducts />} />
        <Route exact path="/totalorder" element={<Totalorder />} />
        <Route exact path="/ordership" element={<Ordership />} />
        <Route exact path="/orderout" element={<Orderout />} />
        <Route exact path="/compelete" element={<Compelete />} />
        <Route exact path="/canceledorder" element={<Canceledorder />} />
        <Route exact path="/returnrqst" element={<Returnrqst />} />
        <Route exact path="/compeletereturn" element={<Compeletereturn />} />
        <Route exact path="/cancelledreturn" element={<Cancelledreturn />} />
        {/* city comp */}
        <Route exact path="/citypradd" element={<Citypradd />} />
        <Route exact path="/citallcat" element={<Citallcat />} />
        <Route exact path="/citallsubcat" element={<Citallsubcat />} />
        <Route exact path="/citallprod" element={<Citallprod />} />
        <Route exact path="/newcategory" element={<Newcategory />} />
        <Route exact path="/newsubcategory" element={<Newsubcategory />} />
        <Route exact path="/newdtcatgory" element={<Newdtcatgory />} />
        <Route exact path="/newdtsubcategory" element={<Newdtsubcategory />} />
        <Route exact path="/newdtcat" element={<Newdtcat />} />
        <Route exact path="/newdtsubcat" element={<Newdtsubcat />} />
      </Routes>
    </div>
  );
}

export default App;

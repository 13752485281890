import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Homecity() {
  // selecting zone code start
  const [zone, setzone] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [stforallnum, setstforallnum] = useState(false);

  const getzone = async () => {
    try {
      const response = await fetch(
        "https://api.jogkart.com/jogkart/admin/citystore/getzone.php"
      );
      const data = await response.json();
      setzone(data);
      const storedCityKey = localStorage.getItem("cityfindkey");
      //   if (storedCityKey) {
      //     setstforallnum(true);
      //     fngetalldata(storedCityKey);
      //     console.log(storedCityKey);

      //     setSelectedOption(
      //       data.find((item) => item.key === storedCityKey)?.city || ""
      //     );
      //     console.log(
      //       data.find((item) => item.key === storedCityKey)?.city || ""
      //     );
      //   } else {
      //     setstforallnum(false);
      //   }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getzone();
  }, []);
  // dropdown start

  // Options for the dropdown
  //  get all products
  const fngetalldata = async (citykey) => {
    try {
      const response = await fetch(
        `https://api.jogkart.com/jogkart/admin/getalldatanum.php?citykey=${citykey}`
      );
      const result = await response.json();
      //   setallnumberdata(result[0]);
      setstforallnum(true);
    } catch (error) {
      console.log(error);
    }
  };
  // Handle the change in selection
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    localStorage.setItem("cityfindkey", event.target.value);
    fngetalldata(event.target.value);
  };

  useEffect(() => {
    // Fetch the stored city key from localStorage when the component loads
    const storedCityKey = localStorage.getItem("cityfindkey");

    if (storedCityKey) {
      const selectedCity = zone.find((item) => item.key === storedCityKey)?.key;
      setSelectedOption(selectedCity || ""); // Set selected option
      fngetalldata(storedCityKey); // Fetch data for the stored key
    }
  }, [zone, fngetalldata]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "gray",
          border: "1px solid black",
          color: "white",
        }}
      >
        <div>
          <div style={{ fontWeight: "bold", fontSize: 20 }}>
            Please select Zone
          </div>
          <div style={{ margin: "20px" }}>
            <select
              id="dropdown"
              value={selectedOption}
              onChange={handleChange}
              style={{
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                fontSize: "16px",
                textTransform: "capitalize",
              }}
            >
              <option style={{ textTransform: "capitalize" }} value="" disabled>
                -- Please Select --
              </option>
              {zone.map((option, index) => (
                <option
                  style={{ textTransform: "capitalize" }}
                  key={index}
                  value={option.key}
                >
                  {option.city}
                </option>
              ))}
            </select>

            {/* Displaying the selected option */}
            {/* {selectedOption && (
          <p
            style={{
              marginTop: "10px",
              fontWeight: "bold",
            }}
          >
            You selected: {selectedOption}
          </p>
        )} */}
          </div>
        </div>
      </div>
      {stforallnum ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Link to={"/citallprod"} className="box78274hhdui">
            All Products
          </Link>
          <Link to={"/citallcat"} className="box78274hhdui">
            All Category
          </Link>
          <Link to={""} className="box78274hhdui">
            All Banner Top
          </Link>
          <Link to={""} className="box78274hhdui">
            All Banner Home
          </Link>
          <Link to={"/citallsubcat"} className="box78274hhdui">
            All Subcategory
          </Link>
          <Link to={""} className="box78274hhdui">
            All Detaild Category
          </Link>
          <Link to={""} className="box78274hhdui">
            All Detaild Subcategory
          </Link>
          <Link to={""} className="box78274hhdui">
            All Order
          </Link>
          <Link to={""} className="box78274hhdui">
            All Dispatched Order
          </Link>
          <Link to={""} className="box78274hhdui">
            All Delivered Order
          </Link>
          <Link to={""} className="box78274hhdui">
            All Cancelled Order
          </Link>
          <Link to={""} className="box78274hhdui">
            All Delivered Order
          </Link>
          <Link to={""} className="box78274hhdui">
            All User
          </Link>
          <Link
            to={"/citypradd"}
            style={{ color: "green" }}
            className="box78274hhdui"
          >
            ADD Products
          </Link>
          <Link
            to={"/newcategory"}
            style={{ color: "orange" }}
            className="box78274hhdui"
          >
            New Catgory
          </Link>
          <Link
            to={"/newsubcategory"}
            style={{ color: "yellow" }}
            className="box78274hhdui"
          >
            New Subcategory
          </Link>
          <Link
            to={"/newdtcatgory"}
            style={{ color: "red" }}
            className="box78274hhdui"
          >
            New Dtcategory
          </Link>
          <Link
            to={"/newdtsubcategory"}
            style={{ color: "#9f23c4" }}
            className="box78274hhdui"
          >
            New Dtsubcategory
          </Link>
        </div>
      ) : (
        <div style={{ fontWeight: "bold", fontSize: 25 }}>
          Select City First
        </div>
      )}
    </div>
  );
}

export default Homecity;
